var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { alpha, styled } from '@mui/material/styles';
import { bgBlur } from '../../utils/cssStyles';
// ----------------------------------------------------------------------
export var StyledArrow = styled('span')(function (_a) {
    var arrow = _a.arrow, theme = _a.theme;
    var SIZE = 14;
    var POSITION = -(SIZE / 2) + 0.5;
    var topStyle = {
        top: POSITION,
        transform: 'rotate(135deg)',
    };
    var bottomStyle = {
        bottom: POSITION,
        transform: 'rotate(-45deg)',
    };
    var leftStyle = {
        left: POSITION,
        transform: 'rotate(45deg)',
    };
    var rightStyle = {
        right: POSITION,
        transform: 'rotate(-135deg)',
    };
    return __assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign({ width: SIZE, height: SIZE, position: 'absolute', borderBottomLeftRadius: SIZE / 4, clipPath: 'polygon(0% 0%, 100% 100%, 0% 100%)', border: "solid 1px ".concat(alpha(theme.palette.mode === 'light' ? theme.palette.grey[500] : theme.palette.common.black, 0.12)) }, bgBlur({
        color: theme.palette.background.paper,
    })), (arrow === 'top-left' && __assign(__assign({}, topStyle), { left: 20 }))), (arrow === 'top-center' && __assign(__assign({}, topStyle), { left: 0, right: 0, margin: 'auto' }))), (arrow === 'top-right' && __assign(__assign({}, topStyle), { right: 20 }))), (arrow === 'bottom-left' && __assign(__assign({}, bottomStyle), { left: 20 }))), (arrow === 'bottom-center' && __assign(__assign({}, bottomStyle), { left: 0, right: 0, margin: 'auto' }))), (arrow === 'bottom-right' && __assign(__assign({}, bottomStyle), { right: 20 }))), (arrow === 'left-top' && __assign(__assign({}, leftStyle), { top: 20 }))), (arrow === 'left-center' && __assign(__assign({}, leftStyle), { top: 0, bottom: 0, margin: 'auto' }))), (arrow === 'left-bottom' && __assign(__assign({}, leftStyle), { bottom: 20 }))), (arrow === 'right-top' && __assign(__assign({}, rightStyle), { top: 20 }))), (arrow === 'right-center' && __assign(__assign({}, rightStyle), { top: 0, bottom: 0, margin: 'auto' }))), (arrow === 'right-bottom' && __assign(__assign({}, rightStyle), { bottom: 20 })));
});
